import React, { useState, useEffect } from "react"
import classNames from "classnames"
import axios from "axios"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

const Notification = () => {
  const hasSeenNotificationMessage = hasSeenNotification()
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    const getBanner = async () => {
      try {
        const params = {
          website: process.env.GATSBY_GITHUB_REPO,
          env: process.env.GATSBY_ENV,
        }

        const response = await axios.get(
          process.env.GATSBY_AIRTABLE_BANNER_ENDPOINT,
          { params }
        )

        let actualBannerContent = response?.data?.content || null

        setBanner(actualBannerContent)
      } catch (error) {}
    }

    getBanner()
  }, [])

  if (!!banner && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          styles["notification"]
        )}
      >
        <button
          aria-label="delete"
          className="delete"
          onClick={() => {
            setNotificationAsSeen()
            setBanner(null)
          }}
          type="button"
        />
        <div
          dangerouslySetInnerHTML={{
            __html: banner?.join(""),
          }}
          className="has-text-centered"
        ></div>
      </div>
    )

  return null
}

export default Notification
